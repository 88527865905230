
//Button
$main-color : #1c6f7c;  // 00acc1
$pinColorRed : red;
$oc-indigo-1 : #dbe4ff;
.fontBold {
	font-weight: bold;
}
.font13 {
	font-size: 13px;
}
.font14 {
	font-size: 14px;
}

.MapWrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	.Button {
		text-align: center;
		width: 32px;
		height: 32px;
		border-radius: 3px;
		background-color: white;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
		&:hover {
			cursor: pointer;
			color: rgba(0, 0, 0, 0.6);
		}
		&:active {
			color: rgba(0, 0, 0, 0.3);
		}
	}
	.ActiveButton {
		color: white;
		background-color: $main-color;
		&:hover {
			cursor: pointer;
			color: $oc-indigo-1;
		}
	}
	.loader {
		position: absolute;
		top: 2px;
		left: 4px;
		height: 25px;
		line-height: 25px;
		background-color: white;
	}

	.MapControlView {
		z-index: 10;
		position: absolute;
		top: 149px;
		right: 9px;
		width: 38px;
		color: #343a40;

		.iconVCenter {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.MyLocationControl {
			font-size: 0.9rem;
			position: relative;
			margin: 1px 3px 5px;
			//line-height: 42px;
			&:hover {
				.ButtonInfo {
					display: block;
				}
			}
		}

		.PlusControl {
			position: relative;
			margin: 25px 3px 3px;
			//line-height: 36px;
			&:hover {
				.ButtonInfo {
					display: block;
				}
			}
		}

		.MinusControl {
			position: relative;
			margin: 1px 3px 5px;
			//line-height: 36px;
			&:hover {
				.ButtonInfo {
					display: block;
				}
			}
		}

		.MarkerShowControl {
			position: relative;
			margin: 1px 3px 5px;
			//line-height: 40px;
			&:hover {
				.ButtonInfo {
					display: block;
				}
			}
		}

		.LayerControl {
			position: relative;
			&:hover {
				.ButtonInfo {
					display: block;
				}
			}
		}

		.LayerControl1 {
			margin: 27px 3px 5px;
		}

		.LayerControl2 {
			margin: 1px 3px 5px;
		}

		.ContentsShowControl {
			font-size: 0.8rem;
			font-weight: 600;
			position: relative;
			margin: 27px 3px 5px;
			//line-height: 34px;
		}

		.diaryOptionBox {
			position: absolute;
			top: 146px;
			right: 40px;
			background-color: #607d8b;
			padding: 5px;
			border-radius: 5px;
		}
	}
	// Button Hover --------------------------------------------------
	.ButtonInfo {
		display: none;
		z-index: 10;
		position: absolute;
		top: 3px;
		//right: 45px;
		//left: -57px;
		height: 26px;
		line-height: 26px;
		font-size: 12px;
		font-weight: 700;
		padding: 0 10px;
		background-color: rgba(0,0,0,0.7);
		border-radius: 5px;
		box-shadow: 0 0 5px rgba(0,0,0,0.4);
		color: white;
		&:before {
			position: absolute;
			right: -7px;
			border-left: 7px solid rgba(0,0,0,0.65);
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			bottom: 6px;
			content: '';
			//margin-left: 31px;
		}
	}
	.buttonSize2 {
		left: -57px;
	}
	.buttonSize3 {
		left: -67px;
	}
	.buttonSize4 {
		left: -77px;
	}
	.buttonSize5 {
		left: -90px;
	}
	.buttonSize6 {
		left: -99px;
	}
}


////////////////////////////////////////////////////////////////////
// MyPosition & Spot Marker
////////////////////////////////////////////////////////////////////
.redPin {background: $pinColorRed; }
.bluePin {background: $main-color;}
.pin {
	z-index: 1;
	width: 30px;
	height: 30px;
	border-radius: 50% 50% 50% 0;
	position: absolute;
	transform: rotate(-45deg);
	left: 50%;
	top: -15px;
	margin: -20px 0 0 -20px;
	//animation-name: bounce; // 아이콘이 떨어지는 효과..
	animation-fill-mode: both;
	animation-duration: 1s;

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: 14px;
		height: 14px;
		margin: 8px 0 0 8px;
		background: white;
		border-radius: 50%;
	}
}
.pulse {
	background: rgba(0,0,0,0.2);
	border-radius: 50%;
	height: 20px;
	width: 20px;
	position: absolute;
	left: 50%;
	top: -15px;
	margin: 11px 0 0 -15px;
	transform: rotateX(55deg);
	z-index: -2;
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		content: "";
		border-radius: 50%;
		height: 60px;
		width: 60px;
		margin: -20px 0 0 -20px;
		animation: pulsate 1s ease-out;
		animation-iteration-count: infinite;
		opacity: 0;
		box-shadow: 0 0 1px 2px #89849b;
		animation-delay: 1.1s;
	}
}
@-moz-keyframes pulsate {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}
@-webkit-keyframes pulsate {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}
@-o-keyframes pulsate {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}
@keyframes pulsate {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}
@-moz-keyframes bounce {
	0% {
		opacity: 0;
		transform: translateY(-2000px) rotate(-45deg);
	}
	60% {
		opacity: 1;
		transform: translateY(30px) rotate(-45deg);
	}
	80% {
		transform: translateY(-10px) rotate(-45deg);
	}
	100% {
		transform: translateY(0) rotate(-45deg);
	}
}
@-webkit-keyframes bounce {
	0% {
		opacity: 0;
		transform: translateY(-2000px) rotate(-45deg);
	}
	60% {
		opacity: 1;
		transform: translateY(30px) rotate(-45deg);
	}
	80% {
		transform: translateY(-10px) rotate(-45deg);
	}
	100% {
		transform: translateY(0) rotate(-45deg);
	}
}
@-o-keyframes bounce {
	0% {
		opacity: 0;
		transform: translateY(-2000px) rotate(-45deg);
	}
	60% {
		opacity: 1;
		transform: translateY(30px) rotate(-45deg);
	}
	80% {
		transform: translateY(-10px) rotate(-45deg);
	}
	100% {
		transform: translateY(0) rotate(-45deg);
	}
}
@keyframes bounce {
	0% {
		opacity: 0;
		transform: translateY(-2000px) rotate(-45deg);
	}
	60% {
		opacity: 1;
		transform: translateY(30px) rotate(-45deg);
	}
	80% {
		transform: translateY(-10px) rotate(-45deg);
	}
	100% {
		transform: translateY(0) rotate(-45deg);
	}
}




