
.introWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .logoBox {
    width: 60px;
    height: 60px;
    padding: 20px;

    .logoImg {
      height: 100%;
      width: 100%;
      background-image: url("../../asset/images/logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 5px;
    }
  }


  .sec {
    width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
  }

  .downloadBox {

    .downText {
      padding: 5px;

      a {
        color: #343a40;
      }
    }
  }

  .text01 {
    font-size: 45px;
    font-weight: 900;
    color: #00d3ed;
  }
  .text02 {
    font-size: 18px;
    //font-weight: bold;
    //color : #a5a5a5;
    padding: 10px 0 0 0;
  }

  .footerInfoWrapper {
    position: absolute;
    bottom: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .footerText01 {
      font-size: 13px;
      color : #a5a5a5;
      padding-top: 2px;
    }

    .footerTextBox {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .footerLinkBox {
      padding-top: 10px;
      flex: 1;
      display: flex;
      flex-direction: row;
    }

    a {
      color: #a5a5a5;
      padding: 3px 7px;
      border-radius: 5px;
      text-decoration: none;
      &:hover {
        cursor: pointer;
        background-color: #eee;
      }
    }
  }
}
