$BaseUrl : 'https://www.campinglive.kr';
$ImgUrl : '#{$BaseUrl}/static/images';


////////////////////////////////
// Default Marker
////////////////////////////////
.imageRound {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	box-sizing: content-box;
}
.markerRound1 {
	position: relative;
	text-align: center;
	top: -20px;
	left: -20px ;
	height: 47px;
	width: 47px;

	.roundDefault {
		display: block;
		margin: 0 auto;
		text-align: center;
		border-radius: 50%;
		cursor: pointer;
		font-size: 10px;
		//border: 1px solid #673ab7;
		height: 100%;
		box-sizing: content-box;
		background-repeat: no-repeat;
		background-size: 47px 47px;
	}
	.friendRound {
		background-image: url("#{$ImgUrl}/marker001.png");
	}
	.publicRound {
		background-image: url("#{$ImgUrl}/marker002.png");
	}
	.hiddenRound {
		background-image: url("#{$ImgUrl}/marker003.png");
	}
	.round2 {
		width: 43px;
		height: 43px;
		line-height: 43px;
		color: #ffffff;
		background-color: #00acc1;
		opacity: 0.9;
	}
	.round3 {
		width: 50px;
		height: 50px;
		line-height: 50px;
		color: #ffffff;
		background-color: #bd954f;
		opacity: 0.9;
	}
}
.markerRound2 {
	.roundDefault {
		display: block;
		margin: 0 auto;
		text-align: center;
		border-radius: 50%;
		cursor: pointer;
		font-size: 10px;
		height: 100%;
		box-sizing: content-box;
		background-repeat: no-repeat;
		background-size: 47px 47px;
	}
	.friendRound {
		width: 8px;
		height: 8px;
		color: #ffffff;
		background-color: #fdab33;
		border: 2px solid #734709;
		opacity: 0.9;
	}
	.publicRound {
		width: 8px;
		height: 8px;
		color: #ffffff;
		background-color: #00e1fd;
		border: 2px solid #1c6f7c;
		opacity: 0.9;
	}
	.hiddenRound {
		width: 8px;
		height: 8px;
		color: #ffffff;
		background-color: #a272fc;
		border: 2px solid #4b02d9;
		opacity: 0.9;
	}
}


////////////////////////////////
// Public Marker
////////////////////////////////

.publicClusterMarker {
	width: 60px;
	height: 40px;
	background-color: #00acc1;
	border: 1px solid #0a8e9f;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.titleText01 {
		font-size: 11px;
		font-weight: bold;
		color: white;
		opacity: .8;
	}
	.titleText02 {
		font-size: 13px;
		font-weight: bold;
		color: white;
	}
}

.publicMarker {
	position: absolute;
	height: 27px;
	padding: 0 15px;
	border-radius: 17px;
	background-color: #01acc1;
	border: 1px solid #0199a4;
	display: flex;
	justify-content: center;
	align-items: center;
	.titleText01 {
		font-size: 12px;
		font-weight: bold;
		color: white;
		opacity: .8;
	}
}
