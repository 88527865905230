$main-color : #008d62;



///////////////////////////////////////////////////////////////////////
// ModalCollectView
///////////////////////////////////////////////////////////////////////
.modalBase, .modalBase2 {
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: #fefefe;
	border-radius: 3px;
	-ms-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-family: "AppleSDGothicNeo-Regular", "Roboto", "Helvetica", "Arial", sans-serif;
	&:focus{
		outline: none;
	}

	.baseBtn {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 35px;
		border-radius: 5px;
		&:hover {
			cursor: pointer;
		}
	}

	.headerBase {
		position: relative;
		height: 70px;
	}

	.bodyBase {
		height: 600px;
	}

	.footerBase {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 70px;
		border-radius: 0 0 5px 5px;

		.cancelBtn {
			position: absolute;
			top: 17px;
			right: 120px;
			width: 80px;

			&:hover {
				background-color: #eee;
			}
		}

		.doBtn {
			position: absolute;
			top: 17px;
			right: 30px;
			width: 80px;
			color: white;
			background-color: $main-color ;
			&:hover {
				background-color: $main-color ;
				opacity: .8;
			}
		}
	}

	.closeBtn {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 35px;
		height: 35px;
		border-radius: 50%;

		&:hover {
			cursor: pointer;
			background-color: #eee;
			opacity: .8;
		}
	}
}

.Overlay {
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
}


.regCampWrapper {
	width: 500px;

	.headerBase {
		display: flex;
		align-items: center;

		.titleText {
			font-size: 16px;
			padding-left: 30px;
		}
	}

	.bodyBase {

		.titleText {
			display: inline-flex;
			height: 50px;
			justify-content: center;
			align-items: center;
			padding: 0 20px;
		}
	}
}


.placeholder {
	margin: 0 auto;
	max-width: 200px;
	min-height: 100px;
	background-color: #eee;
}

@keyframes placeHolderShimmer{
	0%{
		background-position: -468px 0
	}
	100%{
		background-position: 468px 0
	}
}

.animated-background {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: darkgray;
	background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
	background-size: 800px 104px;
	height: 100px;
	position: relative;
}
